import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ItemType, TableConfig, TableUpdateValue } from '../../../table/entities/table';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { GroupService } from '../../services/group';
import { GroupCategory, GroupLevel } from '../../entities/group';
import { UsersService } from '../../services/user';
import { User } from '../../../auth/entities/user';
import { CurafidaAuthService } from '../../../auth/services';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { GroupListItem } from '../../entities/group-list.item';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { CheckBoxItemAdapterComponent } from '../../../table/components/table-adapter/checkbox-item-adapter.component';
import { Router } from '@angular/router';

class SelectableGroup extends GroupListItem {
    selected = false;
}

@Component({
    selector: 'lib-group-list-modal',
    templateUrl: './group-list-modal.component.html',
    styleUrls: ['./group-list-modal.component.scss'],
})
export class GroupListModalComponent implements OnInit {
    isLoading = true;
    isLoadingSuccess = false;
    protected readonly log: Logger;

    title: string;
    selectedGroups: GroupListItem[] = [] as SelectableGroup[];
    groupListConfig: TableConfig<SelectableGroup[]> = new TableConfig();
    saveButtonText: string;
    isMultipleChoice = false;
    onlyGroupsWithMembership = true;
    groupLevel?: GroupLevel;
    showSelectedGroup = true;
    searchLabel: string;
    anyItem: string;
    concernedUser: string;
    loggedInUser: User;
    limit = 50;

    constructor(
        protected modalCtrl: ModalController,
        private authService: CurafidaAuthService,
        private groupService: GroupService,
        private userService: UsersService,
        protected toastService: ToastService,
        private params: NavParams,
        private loggingService: LoggingService,
        public router: Router,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.groupListConfig.isOpenDetailEnable = true;
        this.groupListConfig.itemSettings = [
            {
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '8%',
                columnPosition: 0,
            },
            {
                id: 'name',
                prop: 'name',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                columnPosition: 1,
            },
            {
                id: 'parentName',
                prop: 'parentName',
                header: 'Organisation',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                columnPosition: 2,
            },
            {
                id: 'category',
                prop: 'category',
                header: 'Kategorie',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '32%',
                columnPosition: 3,
            },
        ];
    }

    private _searchTerm: string;

    get searchTerm(): string {
        return this._searchTerm;
    }

    set searchTerm(value: string) {
        this._searchTerm = value;
        this.initGroupList({ offset: 0, limit: this.limit });
    }

    async ngOnInit() {
        this.loggedInUser = await this.authService.getSession().user;
        this.saveButtonText = 'Auswahl bestätigen';
        this.title = await this.params.get('title');
        this.selectedGroups = await this.params.get('selectedGroups');
        this.isMultipleChoice = await this.params.get('isMultipleChoice');
        this.showSelectedGroup = await this.params.get('showSelectedGroup');
        this.searchLabel = await this.params.get('searchString');
        this.anyItem = await this.params.get('anyItem');
        this.groupLevel = await this.params.get('groupLevel');
        this.concernedUser = await this.params.get('concernedUser');
        const tableItems = await this.params.get('tableItems');
        if (tableItems) this.groupListConfig.itemSettings = tableItems;
        if (!this.isMultipleChoice && this.selectedGroups.length > 1) {
            throw new Error(
                'If the single choice option is set, there cannot be more than one selected group as an input.',
            );
        }
        await this.initGroupList({ offset: 0, limit: this.limit });
    }

    async initGroupList(value: TableUpdateValue) {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        try {
            if (this.onlyGroupsWithMembership) {
                this.groupListConfig.list = (await this.userService.getGroupsOfUser(
                    this.loggedInUser.username,
                    value.limit,
                    value.offset,
                    null,
                    null,
                    this.searchTerm,
                    null,
                    null,
                    true,
                    this.groupLevel,
                )) as PaginatedResponse<SelectableGroup[]>;
            } else {
                if (this.router.url.includes('consultation')) {
                    const groups = await this.groupService.getAllConsultationProviderGroups(
                        value.limit,
                        value.offset,
                        true,
                        false,
                        true,
                    );
                    this.groupListConfig.list.items = [];
                    for (const group of groups) {
                        this.groupListConfig.list.items.push(group as SelectableGroup);
                    }
                } else if (this.router.url.includes('order')) {
                    const groups = await this.groupService.getAllOrderProviderGroups(
                        value.limit,
                        value.offset,
                        true,
                        false,
                        true,
                    );
                    this.groupListConfig.list.items = [];
                    for (const group of groups) {
                        this.groupListConfig.list.items.push(group as SelectableGroup);
                    }
                }
                this.groupListConfig.list = PaginatedResponse.init(this.groupListConfig.list.items);
            }
            /* if (this.groupListConfig.list.items.length === 1) {
                this.selectedGroups = [this.groupListConfig.list.items[0]];
                await this.returnSelectedGroups();
            } */
            if (!this.showSelectedGroup) {
                this.groupListConfig.list = PaginatedResponse.init(
                    this.groupListConfig.list.items.filter((group) => !group.selected),
                );
            }
            for (const group of this.groupListConfig.list.items) {
                const parentPath = group.path.split('/');
                group.selected = !!this.selectedGroups.find((g) => g.uuid === group.uuid);
                const groupLevel = this.groupService.getGroupLevel(group.path);
                if (groupLevel === GroupLevel.TENANT || groupLevel === GroupLevel.ORGANIZATION) {
                    group.parentName = '-';
                    if (groupLevel === GroupLevel.TENANT) group.category = GroupCategory.TENANT;
                } else {
                    group.parentName = parentPath[parentPath.length - 2];
                }
                if (!group.category) group.category = GroupCategory.NO_CATEGORY;
            }
            this.isLoading = false;
            this.isLoadingSuccess = true;
        } catch (err) {
            this.log.error('Error in initGroupList', err);
            this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.isLoading = false;
            this.isLoadingSuccess = false;
        }
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    onActionOnItem(clickedGroup: SelectableGroup) {
        clickedGroup.selected = !clickedGroup.selected;
        if (clickedGroup.selected) {
            if (!this.isMultipleChoice) {
                this.selectedGroups = [clickedGroup];
                for (const group of this.groupListConfig.list.items) {
                    if (clickedGroup.uuid !== group.uuid) group.selected = false;
                }
            } else {
                this.selectedGroups.push(clickedGroup);
            }
        } else {
            if (!this.isMultipleChoice) {
                this.selectedGroups = [];
            } else {
                this.selectedGroups = this.selectedGroups.filter((u) => u.uuid !== clickedGroup.uuid);
            }
        }
    }

    async returnSelectedGroups() {
        const result: GroupListItem[] = [];
        for (const selectedGroup of this.selectedGroups as SelectableGroup[]) {
            delete selectedGroup.selected;
            result.push(selectedGroup as GroupListItem);
        }
        await this.modalCtrl.dismiss(result);
    }
}
