// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
@media (max-width: 750px) {
  div {
    display: none !important;
  }
}

@media (min-width: 751px) and (max-width: 900px) {
  div {
    display: none !important;
  }
}
*/
.back-button-title {
  --margin-bottom: 1rem;
}

.prefix {
  margin-right: 0.25rem;
  color: grey;
  font-size: 80% !important;
}

.btn-number {
  position: relative;
}

.badge-number {
  position: absolute;
  top: 29%;
  right: 0;
  width: 100%;
  color: white;
  z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/page-title/page-title.component.scss"],"names":[],"mappings":"AAAA;;;;;;;;;;;;CAAA;AAcA;EACI,qBAAA;AAAJ;;AAGA;EACI,qBAAA;EACA,WAAA;EACA,yBAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,QAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AAAJ","sourcesContent":["/*\n@media (max-width: 750px) {\n  div {\n    display: none !important;\n  }\n}\n\n@media (min-width: 751px) and (max-width: 900px) {\n  div {\n    display: none !important;\n  }\n}\n*/\n\n.back-button-title {\n    --margin-bottom: 1rem;\n}\n\n.prefix {\n    margin-right: 0.25rem;\n    color: grey;\n    font-size: 80% !important;\n}\n\n.btn-number {\n    position: relative;\n}\n\n.badge-number {\n    position: absolute;\n    top: 29%;\n    right: 0;\n    width: 100%;\n    color: white;\n    z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
