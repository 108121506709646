import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { ApiService } from '../../../api';
import { CreateGroupDto, Group, GroupLevel, UpdateGroupDto } from '../../entities/group';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { User, UserRoles } from '../../../auth/entities/user';
import {
    CreateProvidedServiceDto,
    OrderSettings,
    ProvidedService,
    UpdateOrderSettingsDto,
    UpdateProvidedServiceDto,
} from '../../../order/entities/provided-service';
import { ConsultationSettings, UpdateConsultationSettingsDto } from '../../../consultation/entities/provided-service';
import { forkJoin, Observable, of } from 'rxjs';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { FeatureConfigs } from '../../../common/entities/curafida-frontend-configuration';

@Injectable({
    providedIn: 'root',
})
export class GroupService {
    protected readonly log: Logger;

    constructor(
        protected http: HttpClient,
        private loadingService: LoadingService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    getGroup(
        groupPath: string,
        withSubgroups?: boolean,
        withEvents?: boolean,
        withProvidedGroupServices?: boolean,
    ): Promise<Group> {
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-`);
        if (withEvents) url.searchParams.set('withEvents', withEvents.toString());
        if (withSubgroups) url.searchParams.set('withSubgroups', withSubgroups.toString());
        if (withProvidedGroupServices) {
            url.searchParams.set('withProvidedGroupServices', withProvidedGroupServices.toString());
        }
        return this.http.get<Group>(url.toString()).toPromise();
    }

    /***
     * Duplicated with the function getUsers
     * @param groupPath -
     */
    getMembersOfGroup(groupPath: string): Promise<PaginatedResponse<User[]>> {
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-/members`);
        return this.http.get<PaginatedResponse<User[]>>(url.toString()).toPromise();
    }

    /**
     * doUsersShareGroup
     * Checks if two users share a group in common
     * @param user1 - the User object of the first user to compare (usually the logged in user)
     * @param username2 - the String with the username of the second user to compare
     * @param groupPath - optional parameter to check if both users share a specific group instead of any group
     */
    async doUsersShareGroup(user1: User, username2: string, groupPath?: string): Promise<boolean> {
        if (user1.username === username2) return true;
        if (!groupPath) {
            for (const group of user1.groups) {
                const paginatedMembers = await this.getMembersOfGroup(group);
                for (const member of paginatedMembers.items) {
                    if (member.username === username2) return true;
                }
            }
        } else if (user1.groups.includes(groupPath)) {
            const paginatedMembers = await this.getMembersOfGroup(groupPath);
            for (const member of paginatedMembers.items) {
                if (member.username === username2) return true;
            }
        }
        return false;
    }

    async postGroup(group: CreateGroupDto, parentPath: string): Promise<Group> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(parentPath)}/-`);
        return await this.http
            .post<Group>(url.toString(), group)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async putGroup(group: UpdateGroupDto, groupPath: string): Promise<Group> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-`);
        return await this.http
            .put<Group>(url.toString(), group)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async deleteGroup(groupPath: string) {
        this.loadingService.startLoadingModal('Daten werden gelöscht.');
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-`);
        return await this.http
            .delete(url.toString())
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    getGroupLevel(groupPath: string): GroupLevel {
        const levelNumber = groupPath.split('/').length;
        switch (levelNumber) {
            case 5:
                return GroupLevel.INSTITUTION;
            case 4:
                return GroupLevel.ORGANIZATION;
            case 3:
                return GroupLevel.TENANT;
        }
    }

    /*
     * Endpoints for Consultation module
     */
    async getAllConsultationProviderGroups(
        limit?: number,
        offset?: number,
        withGroup?: boolean,
        withConsultationServices?: boolean,
        filterByConsultationsEnabled?: boolean,
        filterByConsultationProvidedServicesEnabled?: boolean,
    ): Promise<Group[]> {
        const url = new URL(`${ApiService.url}consultations/providers`);
        if (limit) url.searchParams.set('limit', limit.toString());
        if (offset) url.searchParams.set('offset', offset.toString());
        if (withGroup) url.searchParams.set('withGroup', withGroup.toString());
        if (withConsultationServices) {
            url.searchParams.set('withConsultationServices', withConsultationServices.toString());
        }
        if (filterByConsultationsEnabled) {
            url.searchParams.set('filterByConsultationsEnabled', filterByConsultationsEnabled.toString());
        }
        if (filterByConsultationProvidedServicesEnabled) {
            url.searchParams.set(
                'filterByConsultationProvidedServicesEnabled',
                filterByConsultationProvidedServicesEnabled.toString(),
            );
        }
        const response = await this.http.get<PaginatedResponse<ConsultationSettings[]>>(url.toString()).toPromise();
        const result = [];
        for (const item of response.items) {
            result.push(item.group);
        }
        if (result.length > 1) result.sort((a, b) => a.name.localeCompare(b.name));
        return result;
    }

    async getProviderConsultationSettings(providerPath: string): Promise<ConsultationSettings> {
        const url = new URL(`${ApiService.url}consultations/providers`);
        const limit = 100;
        const withGroup = true;
        const withConsultationServices = true;
        const filterByConsultationsEnabled = true;
        if (limit) url.searchParams.set('limit', limit.toString());
        if (withGroup) url.searchParams.set('withGroup', withGroup.toString());
        if (withConsultationServices) {
            url.searchParams.set('withConsultationServices', withConsultationServices.toString());
        }
        if (filterByConsultationsEnabled) {
            url.searchParams.set('filterByConsultationsEnabled', filterByConsultationsEnabled.toString());
        }
        const result = await this.http.get<PaginatedResponse<ConsultationSettings[]>>(url.toString()).toPromise();
        return result.items.find((x) => x.group.path === providerPath);
    }

    getConsultationSettings(
        groupPath: string,
        withGroup?: boolean,
        withConsultationServices?: boolean,
    ): Observable<ConsultationSettings> {
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-/consultations/settings`);
        if (withGroup) url.searchParams.set('withGroup', withGroup.toString());
        if (withConsultationServices) {
            url.searchParams.set('withConsultationServices', withConsultationServices.toString());
        }
        return this.http.get<ConsultationSettings>(url.toString());
    }

    getOwnGroupConsultationSettings(groupPath: string): Observable<ConsultationSettings> {
        return this.http.get<ConsultationSettings>(
            `groups/${encodeURIComponent(groupPath)}/-/consultations/settings/ownGroup`,
        );
    }

    updateConsultationSettings$(
        groupPath: string,
        consultationSettings: UpdateConsultationSettingsDto,
    ): Observable<ConsultationSettings> {
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-/consultations/settings`);
        return this.http.put<ConsultationSettings>(url.toString(), consultationSettings);
    }

    async createConsultationProvidedService(
        groupPath: string,
        providedService: CreateProvidedServiceDto,
    ): Promise<ProvidedService> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-/consultations/service`);
        providedService.isProviderService = true;
        providedService.isClientService = false;
        return await this.http
            .post<ProvidedService>(url.toString(), providedService)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async updateConsultationProvidedService(
        groupPath: string,
        providedService: UpdateProvidedServiceDto,
    ): Promise<ProvidedService> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-/consultations/service`);
        providedService.isProviderService = true;
        providedService.isClientService = false;
        return await this.http
            .put<ProvidedService>(url.toString(), providedService)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async deleteConsultationProvidedService(groupPath: string, providedServiceUuid: string): Promise<string> {
        this.loadingService.startLoadingModal('Daten werden gelöscht.');
        const url = new URL(
            `${ApiService.url}groups/${encodeURIComponent(groupPath)}/-/consultations/service/${providedServiceUuid}`,
        );
        return await this.http
            .delete(url.toString(), { ...ApiService.options, responseType: 'text' })
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    /*
     * Endpoints for Order module
     */
    async getAllOrderProviderGroups(
        limit?: number,
        offset?: number,
        withGroup?: boolean,
        withOrderServices?: boolean,
        filterByOrdersEnabled?: boolean,
        filterByOrderProvidedServicesEnabled?: boolean,
    ): Promise<Group[]> {
        const url = new URL(`${ApiService.url}orders/providers`);
        if (limit) url.searchParams.set('limit', limit.toString());
        if (offset) url.searchParams.set('offset', offset.toString());
        if (withGroup) url.searchParams.set('withGroup', withGroup.toString());
        if (withOrderServices) url.searchParams.set('withOrderServices', withOrderServices.toString());
        if (filterByOrdersEnabled) url.searchParams.set('filterByOrdersEnabled', filterByOrdersEnabled.toString());
        if (filterByOrderProvidedServicesEnabled) {
            url.searchParams.set(
                'filterByOrderProvidedServicesEnabled',
                filterByOrderProvidedServicesEnabled.toString(),
            );
        }
        const response = await this.http.get<PaginatedResponse<OrderSettings[]>>(url.toString()).toPromise();
        const result = [];
        for (const item of response.items) {
            result.push(item.group);
        }
        if (result.length > 1) result.sort((a, b) => a.name.localeCompare(b.name));
        return result;
    }

    async getProviderOrderSettings(providerPath: string): Promise<OrderSettings> {
        const url = new URL(`${ApiService.url}orders/providers`);
        const limit = 100;
        const withGroup = true;
        const withOrderServices = true;
        const filterByOrdersEnabled = true;
        if (limit) url.searchParams.set('limit', limit.toString());
        if (withGroup) url.searchParams.set('withGroup', withGroup.toString());
        if (withOrderServices) url.searchParams.set('withOrderServices', withOrderServices.toString());
        if (filterByOrdersEnabled) url.searchParams.set('filterByOrdersEnabled', filterByOrdersEnabled.toString());
        const result = await this.http.get<PaginatedResponse<OrderSettings[]>>(url.toString()).toPromise();
        return result.items.find((x) => x.group.path === providerPath);
    }

    getOwnGroupOrderSettings(groupPath: string): Observable<OrderSettings> {
        return this.http.get<OrderSettings>(`groups/${encodeURIComponent(groupPath)}/-/orders/settings/ownGroup`);
    }

    getOrderSettings(groupPath: string, withGroup?: boolean, withOrderServices?: boolean): Observable<OrderSettings> {
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-/orders/settings`);
        if (withGroup) url.searchParams.set('withGroup', withGroup.toString());
        if (withOrderServices) url.searchParams.set('withOrderServices', withOrderServices.toString());
        return this.http.get<OrderSettings>(url.toString());
    }

    updateOrderSettings$(groupPath: string, orderSettings: UpdateOrderSettingsDto): Observable<OrderSettings> {
        const url = `groups/${encodeURIComponent(groupPath)}/-/orders/settings`;
        return this.http.put<OrderSettings>(url, orderSettings);
    }

    async createOrderProvidedService(
        groupPath: string,
        providedService: CreateProvidedServiceDto,
    ): Promise<ProvidedService> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-/orders/service`);
        return await this.http
            .post<ProvidedService>(url.toString(), providedService)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async updateOrderProvidedService(
        groupPath: string,
        providedService: UpdateProvidedServiceDto,
    ): Promise<ProvidedService> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-/orders/service`);
        return await this.http
            .put<ProvidedService>(url.toString(), providedService)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    deleteOrderProvidedService(groupPath: string, providedServiceUuid: string) {
        const url = `groups/${encodeURIComponent(groupPath)}/-/orders/service/${providedServiceUuid}`;
        return this.http.delete(url.toString());
    }

    initSettings(loggedInUser: User, features: FeatureConfigs): Observable<[OrderSettings, ConsultationSettings]> {
        let orderSettings: Observable<OrderSettings>;
        let consultationSettings: Observable<ConsultationSettings>;
        if (loggedInUser.roles.includes(UserRoles.read_consultation) && features.consultation?.enabled) {
            orderSettings = this.getOwnGroupOrderSettings(loggedInUser.groups[0]);
        }
        if (loggedInUser.roles.includes(UserRoles.read_consultation) && features.consultation?.enabled) {
            consultationSettings = this.getOwnGroupConsultationSettings(loggedInUser.groups[0]);
        }
        if (orderSettings === undefined && consultationSettings === undefined) {
            return of();
        }
        return forkJoin([orderSettings, consultationSettings]);
    }
}
