import { Group } from './group';
import { ActionMenuItem } from '../../table/entities/action-menu.item';

export class GroupListItem extends Group {
    created_on?: string;
    created_by?: string;
    tenantName?: string;
    parentName?: string;
    titleName?: string;

    //frontendVariable
    actions?: ActionMenuItem[];
}
