import {
    AfterViewInit,
    Component,
    DoCheck,
    ElementRef,
    EnvironmentInjector,
    HostListener,
    NgZone,
    ViewChild,
} from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../../libs/config/services';
import { DbService } from '../../../../libs/measurement/services/db-service/db-service.service';
import { Logger, LoggingService, LogLevel } from '../../../../libs/logging/logging.service';
import { ThemeService } from '../../../../libs/common/services/theme/theme.service';
import { SplashScreenStateService } from '../../../../libs/common/services/splash-screen-state.service';
import { LoadingService } from '../../../../libs/common/services/loading/loading.service';
import { Subscription } from 'rxjs';
import { ToastPosition } from '../../../../libs/common/entities/toast/toast-message';
import { ToastrService } from 'ngx-toastr';
import { HostListenerService } from '../../../../libs/common/services/host-listener/host-listener.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { NavigationEnd, Router } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { environment } from '../environments/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import { ToolbarType } from '../../../../libs/common/entities/view/toolbar-type';
import { ToolbarService } from '../../../../libs/common/services/toolbar/toolbar.service';
import { App } from '@capacitor/app';
import { register } from 'swiper/element/bundle';
register();
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit, DoCheck {
    @ViewChild('ionHeader', { read: ElementRef }) header: ElementRef;
    isPlatformReady = false;
    isSplashEnable = false;
    protected readonly ToolbarType = ToolbarType;
    private readonly log: Logger;
    private loading: HTMLIonLoadingElement;
    private loadingSubscribe: Subscription;

    constructor(
        private platform: Platform,
        protected readonly configService: ConfigService,
        private translate: TranslateService,
        private dbService: DbService,
        private loggingService: LoggingService,
        private themeService: ThemeService,
        public splashScreenStateService: SplashScreenStateService,
        public loadingController: LoadingController,
        public loadingService: LoadingService,
        private hostListenerService: HostListenerService,
        private elementRef: ElementRef,
        private toastrService: ToastrService,
        private screenOrientation: ScreenOrientation,
        private router: Router,
        public toolbarService: ToolbarService,
        public environmentInjector: EnvironmentInjector,
        private zone: NgZone,
    ) {
        this.splashScreenStateService.$splashScreenActivationState.subscribe((next) => {
            this.isSplashEnable = next;
        });
        this.splashScreenStateService.start();
        this.log = this.loggingService.getLogger(this.constructor.name);
        if (window.location.hostname + ':' + window.location.port === 'localhost:8100') {
            this.log.setLevel(LogLevel.TRACE);
        } else {
            this.log.setLevel(LogLevel.INFO);
        }

        this.initializeApp();
        this.initLoadingComponent();
        this.translate.addLangs(['de']);
        this.translate.setDefaultLang('de');
        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/de/) ? browserLang : 'de');
    }

    @HostListener('document:visibilitychange', ['$event'])
    handleVisibilityChange(event: Event): void {
        this.hostListenerService.visibilityChangeEvent.next(event);
    }

    async initializeApp(): Promise<void> {
        this.configService.initFromJson();
        this.themeService.setColorFromEnvironment(this.configService.config);

        registerLocaleData(localeDe, 'de');
        if (this.platform.is('capacitor')) {
            SplashScreen.hide();
            await StatusBar.setOverlaysWebView({ overlay: false });
            await StatusBar.setStyle({ style: Style.Light });
            // only for android. Doesn't work for ios
            await StatusBar.setBackgroundColor({ color: environment.theme.colors.primary });
            await StatusBar.show();
        }
        this.isPlatformReady = true;
        if (this.platform.is('tablet')) {
            this.screenOrientation.unlock();
            this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
        }
        this.log.debug(`Platform is ready for using native features: ${this.platform.platforms()}`);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url === '/member/kiosk') {
                    // only to hide the native toolbar
                    this.elementRef.nativeElement.style.setProperty('--roulet-height', 0);
                } else {
                    if (this.header) ThemeService.getTopHeight(this.header, this.elementRef, this.platform);
                }
            }
        });
        this.dbService.getDbConnection();
        // });
        this.platform.resume.subscribe(() => {
            this.log.info('App is in foreground');
        });
        this.platform.pause.subscribe(() => {
            this.log.info('App is in background');
        });
        App.addListener('appUrlOpen', (event) => {
            this.zone.run(() => {
                const slug = event.url.split(this.configService.config.deepLinking.host).pop();
                if (slug) {
                    this.router.navigateByUrl(slug);
                }
            });
        });
    }

    async initLoadingComponent(): Promise<void> {
        this.loadingSubscribe = this.loadingService.subscribeLoadingModal((next) => {
            if (next.state) {
                this.loadingController
                    .create({
                        cssClass: 'my-custom-class',
                        message: next.message || 'Daten werden versendet.',
                    })
                    .then(async (loading) => {
                        this.loading = loading;
                        if (this.loadingService.counter === 0) {
                            await this.loading.dismiss();
                        } else {
                            await this.loading.present();
                            if (this.loadingService.counter === 0) await this.loading.dismiss();
                        }
                    });
            } else {
                if (this.loading) this.loading?.dismiss();
            }
        });
    }

    ngDoCheck(): void {
        if (this.router.url !== '/member/kiosk') {
            if (this.header) ThemeService.getTopHeight(this.header, this.elementRef, this.platform);
        }
    }

    async ngAfterViewInit(): Promise<void> {
        if (this.platform.is('android') || this.platform.is('ios')) {
            this.toastrService.toastrConfig.positionClass = ToastPosition.TOP_CENTER;
        } else {
            this.toastrService.toastrConfig.positionClass = ToastPosition.TOP_RIGHT;
        }
    }
}
