import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CurafidaTextInputComponent } from './curafida-input/curafida-text-input/curafida-text-input.component';
import { CurafidaSelectInputComponent } from './curafida-input/curafida-select-input/curafida-select-input.component';
import { CurafidaTextAreaComponent } from './curafida-input/curafida-text-area/curafida-text-area.component';
import { CurafidaDateInputComponent } from './curafida-input/curafida-date-input/curafida-date-input.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { FileUploadModule } from 'ng2-file-upload';
import { CurafidaSegmentComponent } from './curafida-segment/curafida-segment.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { LanguageSelectionComponent } from './language-selection/language-selection.component';
import { DateTimePickerComponent } from './date-picker/date-time-picker/date-time-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { CurafidaUploadInputComponent } from './curafida-input/curafida-upload-input/curafida-upload-input.component';
import { MainModalComponent } from './main-modal/main-modal.component';
import { CurafidaModalSelectInputComponent } from './curafida-input/curafida-modal-select-input/curafida-modal-select-input.component';
import { CurafidaPopoverTabComponent } from './curafida-popover-tab/curafida-popover-tab.component';
import { CurafidaTextInputWithButtonComponent } from './curafida-input/curafida-text-input-with-button/curafida-text-input-with-button.component';
import { CurafidaCheckboxInputComponent } from './curafida-input/curafida-checkbox-input/curafida-checkbox-input.component';
import { CurafidaTextInputAutocompleteComponent } from './curafida-input/curafida-text-input-autocomplete/curafida-text-input-autocomplete.component';
import { CurafidaSearchAndButtonComponent } from './curafida-search-and-button/curafida-search-and-button.component';
import { LoadingTutorialModalComponent } from './loading-tutorial-modal/loading-tutorial-modal.component';
import { SwitchComponent } from './switch/switch.component';
import { SplashComponent } from './splash/splash.component';
import { CurafidaCardComponent } from './curafida-card/curafida-card.component';
import { DataAuthComponent } from '../../therapy/components/data-auth/data-auth.component';
import { CurafidaInputErrorsComponent } from './curafida-input/curafida-input-errors/curafida-input-errors.component';
import { CurafidaSaveCancelButtonComponent } from './curafida-save-cancel-button/curafida-save-cancel-button.component';
import { CurafidaRadioButtonInputComponent } from './curafida-input/curafida-radio-button-input/curafida-radio-button-input.component';
import { TableModule } from '../../table/table.module';
import { StepProgressBarComponent } from './step-progress-bar/step-progress-bar.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { CheckboxListModalComponent } from './checkbox-list-modal/checkbox-list-modal.component';
import { CurafidaUploadButtonComponent } from './curafida-input/curafida-upload-button/curafida-upload-button.component';
import { AcceptRequestModalComponent } from './accept-request-modal/accept-request-modal.component';
import { CurafidaJsonReaderComponent } from './curafida-json-read/curafida-json-reader.component';
import { CurafidaAppListComponent } from './curafida-app-list/curafida-app-list.component';
import { CurafidaAppIconComponent } from './curafida-app-icon/curafida-app-icon.component';
import { PatientReportModalComponent } from './patient-report-modal/patient-report-modal.component';
import { CurafidaTogglePanelComponent } from './curafida-toggle-panel/curafida-toggle-panel.component';
import { CurafidaTextAreaFullHeightComponent } from './curafida-input/curafida-text-area-full-height/curafida-text-area-full-height.component';
import { CurafidaOpenModalInputComponent } from './curafida-input/curafida-open-modal-input/curafida-open-modal-input.component';
import { CurafidaContentInputComponent } from './curafida-input/curafida-content-input/curafida-content-input.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { TranslationModule } from '../../translation/translation.module';
import { OfflineMediaSyncComponent } from './offline-media-sync/offline-media-sync.component';
import { CurafidaTimelineDateLabelPipe } from './curafida-timeline/curafida-timeline-date-label.pipe';
import { TimelineItemIconConfigPipe } from './curafida-timeline/curafida-timeline-item-icon-config.pipe';
import { TimelineIconPathPipe } from './curafida-timeline/curafida-timeline-icon-path.pipe';
import { PatientJournalItemDescriptionPipe } from '../../journal/pipes/patient-journal-item-description.pipe';
import { PlanerItemStatePipe } from './patient-dossier/care-planer/planer-item-state.pipe';
import { HateoasModule } from '../../hateoas/hateoas.module';
import { CurafidaTableComponent } from '../../table/components/curafida-table/curafida-table.component';
import { LoadingComponent } from './loading/loading.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { CheckFeaturesPipe } from '../../config/pipes/check-features.pipe';
import { MenuItemsFilterPipe } from '../../config/pipes/menu-items-filter.pipe';

@NgModule({
    declarations: [
        CurafidaTogglePanelComponent,
        CurafidaAppListComponent,
        CurafidaAppIconComponent,
        CheckboxListModalComponent,
        CurafidaTextInputComponent,
        CurafidaSelectInputComponent,
        CurafidaTextAreaComponent,
        CurafidaDateInputComponent,
        CurafidaSegmentComponent,
        CurafidaPopoverTabComponent,
        PageTitleComponent,
        LanguageSelectionComponent,
        DateTimePickerComponent,
        CurafidaUploadInputComponent,
        MainModalComponent,
        CurafidaModalSelectInputComponent,
        CurafidaTextInputWithButtonComponent,
        CurafidaCheckboxInputComponent,
        CurafidaRadioButtonInputComponent,
        CurafidaTextInputAutocompleteComponent,
        CurafidaSearchAndButtonComponent,
        LoadingTutorialModalComponent,
        SwitchComponent,
        SplashComponent,
        CurafidaCardComponent,
        DataAuthComponent,
        CurafidaInputErrorsComponent,
        CurafidaSaveCancelButtonComponent,
        StepProgressBarComponent,
        ErrorMessageComponent,
        CurafidaUploadButtonComponent,
        CurafidaOpenModalInputComponent,
        AcceptRequestModalComponent,
        CurafidaJsonReaderComponent,
        PatientReportModalComponent,
        CurafidaTextAreaFullHeightComponent,
        CurafidaContentInputComponent,
        OfflineMediaSyncComponent,
        ToolbarComponent,
        SideMenuComponent,
    ],
    imports: [
        IonicModule,
        TranslationModule,
        TableModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AutosizeModule,
        FileUploadModule,
        RouterModule,
        DpDatePickerModule,
        CurafidaTimelineDateLabelPipe,
        TimelineItemIconConfigPipe,
        TimelineIconPathPipe,
        PatientJournalItemDescriptionPipe,
        HateoasModule,
        CurafidaTableComponent,
        LoadingComponent,
        CheckFeaturesPipe,
        MenuItemsFilterPipe,
    ],
    exports: [
        CurafidaTogglePanelComponent,
        CurafidaAppListComponent,
        CurafidaAppIconComponent,
        CheckboxListModalComponent,
        FormsModule,
        IonicModule,
        TranslateModule,
        TranslationModule,
        ReactiveFormsModule,
        CommonModule,
        CurafidaTextInputComponent,
        CurafidaSelectInputComponent,
        CurafidaTextAreaComponent,
        CurafidaDateInputComponent,
        CurafidaSegmentComponent,
        CurafidaPopoverTabComponent,
        PageTitleComponent,
        DateTimePickerComponent,
        CurafidaUploadInputComponent,
        MainModalComponent,
        CurafidaModalSelectInputComponent,
        CurafidaTextInputWithButtonComponent,
        CurafidaCheckboxInputComponent,
        CurafidaRadioButtonInputComponent,
        CurafidaTextInputAutocompleteComponent,
        CurafidaSearchAndButtonComponent,
        LoadingTutorialModalComponent,
        SwitchComponent,
        SplashComponent,
        CurafidaCardComponent,
        DataAuthComponent,
        CurafidaInputErrorsComponent,
        CurafidaSaveCancelButtonComponent,
        StepProgressBarComponent,
        ErrorMessageComponent,
        CurafidaUploadButtonComponent,
        CurafidaOpenModalInputComponent,
        AcceptRequestModalComponent,
        CurafidaJsonReaderComponent,
        PatientReportModalComponent,
        CurafidaTextAreaFullHeightComponent,
        CurafidaContentInputComponent,
        OfflineMediaSyncComponent,
        ToolbarComponent,
        SideMenuComponent,
    ],
    providers: [PlanerItemStatePipe, PatientJournalItemDescriptionPipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonComponentsModule {}
